<template>
  <div class="container">
    <div class="tabs-nav">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="成绩汇总" name="0">
          <Summary ref="summary" />
        </el-tab-pane>
				<el-tab-pane label="艺术学习" name="1">
				  <ArtLearning ref="artLearning" />
				</el-tab-pane>
				<el-tab-pane label="艺术实践" name="2">
				  <ArtPractice ref="artPractice" />
				</el-tab-pane>
				<el-tab-pane label="特长表现" name="3">
				  <SpecialSkill ref="specialSkill" />
				</el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Summary from './component/resultsSummary'
import ArtLearning from './component/artLearning'
import ArtPractice from './component/artPractice'
import SpecialSkill from './component/specialSkill'
export default {
  data() {
    return {
      activeName: "0",
    };
  },
  components: {
    Summary,
		ArtLearning,
		ArtPractice,
		SpecialSkill
  },
  created() {
    this.handleClick()
  },
  methods: {
    handleClick(tab, event) {
      this.$nextTick(()=>{
        switch(this.activeName) {
          case '0': this.$refs.summary.getFirst();break;
					case '1': this.$refs.artLearning.handleClick();break;
					case '2': this.$refs.artPractice.handleClick();break;
					case '3': this.$refs.specialSkill.handleClick();break;
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .tabs-nav {
    width: 100%;
    padding: 15px 16px 30px;
    background: #fff;
    border-radius: 4px 4px 0px 0px;
    box-sizing: border-box;
  }
}
</style>
<style scoped>
.el-tabs--top >>> .el-tabs__item.is-top:nth-child(2){
  padding-left: 20px;
}
</style>