<template>
	<div class="web-content">
		<div class="search">
		  <el-form :inline="true" :model="queryParams" class="demo-form-inline">
				<el-row type="flex" justify="space-between" align="middle">
					<el-col :span="14" style="height: 55px">
						<el-form-item prop="classId">
						  <el-select
						    v-model="queryParams.classId"
						    placeholder="班级"
						    style="width: 120px"
								@change="getTermList"
						  >
						    <el-option v-for="item,index in classlist" :key="index" :label="item.deptName" :value="item.deptId"></el-option>
						  </el-select>
						</el-form-item>
						<el-form-item prop="termName">
						  <el-select
						    v-model="queryParams.termName"
						    placeholder="学期"
						    style="width: 188px"
						  >
						    <el-option v-for="item,index in termlist" :key="index" :label="item.termName" :value="item.termName"></el-option>
						  </el-select>
						</el-form-item>
					</el-col>
					<el-col :span="10" style="height: 55px;text-align: right">
					  <el-form-item>
					    <el-input
					      v-model="queryParams.userNameOrLoginName"
					      placeholder="输入学生姓名/学籍号"
					      style="width: 283px"
					      clearable
					    ></el-input>
					  </el-form-item>
					  <el-form-item>
					    <el-button type="primary" @click="getList">搜索</el-button>
					  </el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<el-row type="flex" justify="space-between">
			<el-col :span="6">
				<h3>艺术课出勤率</h3>
			</el-col>
		</el-row>
		<el-table :data="tableData" style="width: 100%" border :header-cell-style="tableHeaderColor"
			:cell-style="cellStyleFun">
			<el-table-column type="index" label="序号" width="50"> </el-table-column>
			<el-table-column prop="userName" label="学生姓名" width="150"> </el-table-column>
			<el-table-column prop="userNum" label="学籍号"> </el-table-column>
			<el-table-column prop="className" label="班级" width="180">
			</el-table-column>
			<el-table-column prop="artShouldNum" label="艺术课应出勤" width="120">
			</el-table-column>
			<el-table-column prop="artRealNum" label="艺术课实出勤" width="120">
			</el-table-column>
			<el-table-column prop="artRatio" label="艺术课出勤率" width="120">
			</el-table-column>
			<el-table-column prop="indexUserScore" label="综素得分" width="120">
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
import { getTerm } from '@/api/public/search.js'
import { updArtAttendRt } from '@/api/teacher/quality/artistic'
export default {
	data() {
		return {
			queryParams: {
				termName: '',
				classId: '',
				userNameOrLoginName: ''
			},
			typeId: '333',
			tableData: [],
			termlist: [],
			classlist: [],
		}
	},
	methods: {
		// 初始化
		getFirst() {
			this.$store.dispatch('GetClassList', this.typeId).then((res) => {
				this.classlist = res.data
				this.queryParams.classId = this.$store.getters.queryForm.deptId
				this.getTermList()
			})
		},
		// 主列表
		getList() {
			this.tableData = []
			updArtAttendRt(this.queryParams).then((res) => {
				this.tableData = res.data
			})
		},
		// 重置查询
		handleQry() {
			this.queryParams.pageNum = 1
			this.getList()
		},
		// 按年级查学期
		getTermList() {
			// 取年级
			let gr = this.classlist.filter(v => v.deptId == this.queryParams.classId)[0].gradeYear
			getTerm({
				gradeYear: gr
			}).then(res => {
				let d = res.data
				this.termlist = d
				this.queryParams.termName = d[0].termName
				this.queryParams.grade = gr
				this.getList()
			})
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/el-tabs.scss';
@import '@/assets/scss/studentResult.scss';
</style>