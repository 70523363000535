<template>
	<div class="web-content">
		<div class="search">
		  <el-form :inline="true" :model="queryParams" class="demo-form-inline">
				<el-row type="flex" justify="space-between" align="middle">
					<el-col :span="14" style="height: 55px">
						<el-form-item prop="classId">
						  <el-select
						    v-model="queryParams.classId"
						    placeholder="班级"
						    style="width: 120px"
								@change="getTermList"
						  >
						    <el-option v-for="item,index in classlist" :key="index" :label="item.deptName" :value="item.deptId"></el-option>
						  </el-select>
						</el-form-item>
						<el-form-item prop="termName">
						  <el-select
						    v-model="queryParams.termName"
						    placeholder="学期"
						    style="width: 188px"
						  >
						    <el-option v-for="item,index in termlist" :key="index" :label="item.termName" :value="item.termName"></el-option>
						  </el-select>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<el-row type="flex" justify="space-between">
			<el-col :span="6">
				<h3>艺术课考勤</h3>
			</el-col>
			<el-col :span="18" class="btn" style="text-align: right">
				<el-button type="primary" plain @click="handleAdd">新增考勤记录</el-button>
			</el-col>
		</el-row>
		<el-table :data="tableData" style="width: 100%" border :header-cell-style="tableHeaderColor"
			:cell-style="cellStyleFun">
			<el-table-column type="index" label="序号" width="50"> </el-table-column>
			<el-table-column prop="className" label="班级" width="150"> </el-table-column>
			<el-table-column prop="courseName" label="艺术课程" width="150"> </el-table-column>
			<el-table-column prop="shangkeTime" label="上课时间" width="180">
			</el-table-column>
			<el-table-column prop="shangkeTeacher" label="上课老师" width="120">
			</el-table-column>
			<el-table-column prop="studentNum" label="学生总数" width="120">
			</el-table-column>
			<el-table-column prop="attendanceNum" label="出勤人数" width="120">
			</el-table-column>
			<el-table-column prop="unattendanceNum" label="缺勤人数" width="120">
			</el-table-column>
			<el-table-column prop="attendanceRate" label="出勤率" width="120">
			</el-table-column>
			<el-table-column prop="createTime" label="记录创建时间" width="180">
			</el-table-column>
			<el-table-column label="操作" width="180" fixed="right">
				<template slot-scope="scope">
					<el-button @click="handleDetail(scope.row)" type="text" size="small">查看详情</el-button>
					<el-button @click="handleEdit(scope.row)" type="text" size="small">数据维护</el-button>
				</template>
			</el-table-column>
		</el-table>

		<pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize"
			@pagination="getList" />


		<!-- 数据维护 && 添加 -->
		<el-dialog title="艺术课考勤" center width="850px" :visible.sync="open" :close-on-click-modal="false"
			:destroy-on-close="true">
			<el-form :model="form" :rules="rules" ref="form">
				<el-form-item label="班级：">
					<span class="scontent">{{ form.className }}</span>
				</el-form-item>
				<el-form-item label="学期：">
					<span class="scontent">{{ form.termName }}</span>
				</el-form-item>
				<el-form-item label="艺术课程：" prop="courseName">
					<el-input style="width: 240px" v-model="form.courseName" placeholder="请输入课程名称"></el-input>
				</el-form-item>
				<el-form-item label="上课时间：" prop="shangkeTime">
					<el-date-picker style="width: 240px" v-model="form.shangkeTime" type="datetime"
						value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" :picker-options="ltToday">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="上课老师：" prop="shangkeTeacher">
					<el-input v-model="form.shangkeTeacher" style="width: 240px" placeholder="请输入上课老师"></el-input>
				</el-form-item>
				<el-form-item label="学生考勤：">
					<el-row :gutter="10">
						<el-col :span="4">学生总数：{{ studentTotal }}人</el-col>
						<el-col :span="3">出勤：{{ outStudent }}人</el-col>
						<el-col :span="3">缺勤：{{ inStudent }}人</el-col>
						<el-col :span="10">
							<el-input v-model="searchStr" size="mini" style="width: 150px" placeholder="请输入姓名" clearable></el-input>
							<el-button type="primary" size="mini" style="margin-left: 10px" @click="handleSearch">搜索</el-button>
						</el-col>
					</el-row>
					<el-table style="width: 100%" border :header-cell-style="tableHeaderColor" :cell-style="cellStyleFun"
						:data="form.attendanceStudents">
						<el-table-column type="index" label="序号" width="50"></el-table-column>
						<el-table-column property="userName" label="学生姓名" width="150"></el-table-column>
						<el-table-column property="loginName" label="学籍号" min-width="180"></el-table-column>
						<el-table-column property="attendanceStatus" label="出勤状态" width="130">
							<template slot-scope="scope">
								{{ scope.row.attendanceStatus == '0' ? '缺勤' : '出勤' }}
							</template>
						</el-table-column>
						<el-table-column property="unattendanceCause" label="缺勤原因" width="150"></el-table-column>
						<el-table-column label="操作" width="120">
							<template slot-scope="scope">
								<el-button v-if="scope.row.attendanceStatus == '1'" @click="handleStatus(scope.row, scope.$index)"
									type="text" size="small">
									缺勤
								</el-button>
								<el-button v-else @click="handleStatus(scope.row, scope.$index)" type="text" size="small">
									出勤
								</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>
			</el-form>

			<el-dialog width="500px" title="缺勤原因" :visible.sync="openInner" append-to-body center
				:close-on-click-modal="false" :destroy-on-close="true">
				<el-form :model="causeForm" ref="causeForm" :rules="rules">
					<el-form-item prop="cause" label="缺勤原因">
						<br />
						<el-radio-group v-model="causeForm.cause">
							<el-radio label="事假">事假</el-radio>
							<el-radio label="病假">病假</el-radio>
							<el-radio label="其他">其他</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="备注说明" prop="causeRemake">
						<el-input v-model="causeForm.causeRemake" type="textarea" :autosize="{ minRows: 4, maxRows: 6 }"
							placeholder="备注说明"></el-input>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button @click="openInner = false">取 消</el-button>
					<el-button type="primary" @click="handleCause">确 定</el-button>
				</div>
			</el-dialog>

			<div slot="footer" class="dialog-footer">
				<el-button @click="open = false">取 消</el-button>
				<el-button type="primary" @click="handleSubmit">确 定</el-button>
			</div>
		</el-dialog>

		<!-- 查看详情 -->
		<el-dialog title="艺术课考勤" center width="850px" :visible.sync="openDetail" :close-on-click-modal="false"
			:destroy-on-close="true">
			<el-form :model="form" :rules="rules" ref="form">
				<el-form-item label="班级：">
					<span class="scontent">{{ form.className }}</span>
				</el-form-item>
				<el-form-item label="学期：">
					<span class="scontent">{{ form.termName }}</span>
				</el-form-item>
				<el-form-item label="课程名称：">
					<span class="scontent">{{ form.courseName }}</span>
				</el-form-item>
				<el-form-item label="上课时间：">
					<span class="scontent">{{ form.shangkeTime }}</span>
				</el-form-item>
				<el-form-item label="上课老师：">
					<span class="scontent">{{ form.shangkeTeacher }}</span>
				</el-form-item>
				<el-form-item label="学生考勤：">
					<el-row><el-col></el-col></el-row>
					<el-row :gutter="10">
						<el-col :span="4">学生总数：{{ form.studentNum }}人</el-col>
						<el-col :span="3">出勤：{{ form.attendanceNum }}人</el-col>
						<el-col :span="3">缺勤：{{ form.unattendanceNum }}人</el-col>
						<el-col :span="14">
							<el-select v-model="status" placeholder="请选择" size="mini" style="width: 150px;margin-right:10px"
								clearable>
								<el-option label="缺勤" value="0"></el-option>
								<el-option label="出勤" value="1"></el-option>
							</el-select>
							<el-input v-model="searchStr" size="mini" style="width: 150px" placeholder="请输入姓名" clearable></el-input>
							<el-button type="primary" size="mini" style="margin-left: 10px" @click="handleSearch">搜索</el-button>
						</el-col>
					</el-row>
					<el-table style="width: 100%" border :header-cell-style="tableHeaderColor" :cell-style="cellStyleFun"
						:data="form.attendanceStudents">
						<el-table-column type="index" label="序号" width="50"></el-table-column>
						<el-table-column property="userName" label="学生姓名" width="150"></el-table-column>
						<el-table-column property="loginName" label="学籍号" min-width="180"></el-table-column>
						<el-table-column property="attendanceStatus" label="出勤状态" width="130">
							<template slot-scope="scope">
								{{ scope.row.attendanceStatus == '0' ? '缺勤' : '出勤' }}
							</template>
						</el-table-column>
						<el-table-column property="unattendanceCause" label="缺勤原因" width="150"></el-table-column>
					</el-table>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">关 闭</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { getTerm } from '@/api/public/search.js'
	import { getClassById } from '@/api/teacher/quality/health'
	import { artAttendList, updArtAttend } from '@/api/teacher/quality/artistic'
	export default {
		data() {
			return {
				total: 0,
				queryParams: {
					classId: '',
					termName: '',
					pageNum: 1,
					pageSize: 10,
				},
				typeId: '333',
				tableData: [],
				termlist: [],
				classlist: [],
				studentlist: [],
				open: false,
				openInner: false,
				searchStr: '',
				status: '',
				form: {},
				rules: {
					courseName: [{
						required: true,
						message: '请请输入课程名称',
						trigger: 'blur'
					}],
					shangkeTime: [{
						required: true,
						message: '请选择上课时间',
						trigger: 'change'
					}, ],
					shangkeTeacher: [{
						required: true,
						message: '请输入上课教师',
						trigger: 'blur'
					}, ],
					cause: [{
						required: true,
						message: '请选择缺勤原因',
						trigger: 'change'
					}, ],
					causeRemake: [{
						required: true,
						message: '请输入备注说明',
						trigger: 'blur'
					}, ],
				},
				studentTotal: 0, // 学生总数
				outStudent: 0, // 出勤人数
				inStudent: 0, // 缺勤人数
				causeForm: {},
				rowData: {},
				index: '',
				openDetail: false,
			}
		},
		watch: {
			'form.attendanceStudents': {
				handler(val) {
					this.studentTotal = val.length
					this.outStudent = 0
					this.inStudent = 0
					val.forEach((item) => {
						if (item.attendanceStatus == '0') {
							this.inStudent++
						}
						if (item.attendanceStatus == '1') {
							this.outStudent++
						}
					})
				},
				deep: true,
			},
		},
		methods: {
			getFirst() {
				this.$store.dispatch('GetClassList', this.typeId).then((res) => {
					this.classlist = res.data
					this.queryParams.classId = this.$store.getters.queryForm.deptId
					this.getTermList()
				})
			},
			getList() {
				artAttendList(this.queryParams).then((res) => {
					this.tableData = res.data.list
					this.total = res.data.total
				})
			},
			handleQuery(data) {
				this.queryParams.termName = data.term
				this.queryParams.classId = data.deptId
				this.getList()
			},
			handleAdd() {
				this.reset()
				this.form.termName = this.queryParams.termName
				this.form.classId = this.queryParams.classId
				this.classlist.forEach((item) => {
					if (this.form.classId == item.deptId) {
						this.form.className = item.deptName
					}
				})
				let courseList = []
				let course = this.$store.getters.userInfo.courseItem
				// 教授多个科目时
				if (Array.isArray(course)) {
					courseList = course
				} else {
					// 只教1个科目时
					courseList.push(course)
				}
				this.getClassStudent(this.form.classId)
			},
			handleSearch() {
				this.form.attendanceStudents = this.studentlist
				if (this.searchStr && this.status) {
					this.form.attendanceStudents = this.form.attendanceStudents.filter(
						(item) => {
							return this.status === item.attendanceStatus && item.userName.indexOf(this.searchStr) != -1
						}
					)
				} else if (this.searchStr) {
					this.form.attendanceStudents = this.form.attendanceStudents.filter(
						(item) => {
							return item.userName.indexOf(this.searchStr) != -1
						}
					)
				} else if (this.status) {
					this.form.attendanceStudents = this.form.attendanceStudents.filter(
						(item) => {
							return this.status === item.attendanceStatus
						}
					)
				} else {
					this.form.attendanceStudents = this.studentlist
				}
			},
			handleStatus(row, index) {
				if (row.attendanceStatus == '1') {
					this.openInner = true
					this.rowData = row
					this.index = index
					this.causeForm = {
						cause: undefined,
						causeRemake: undefined,
					}
					this.resetForm('causeForm')
				} else if (row.attendanceStatus == '0') {
					this.$set(this.form.attendanceStudents, index, {
						...row,
						attendanceStatus: '1',
						unattendanceCause: '',
					})
				}
			},
			handleCause() {
				this.$refs.causeForm.validate((valid) => {
					if (valid) {
						this.$set(this.form.attendanceStudents, this.index, {
							...this.rowData,
							attendanceStatus: '0',
							unattendanceCause: this.causeForm.cause + '：' + this.causeForm.causeRemake,
						})
						this.openInner = false
					}
				})
			},
			handleSubmit() {
				this.$refs.form.validate(valid => {
					if (valid) {
						updArtAttend(this.form).then(res => {
							this.$message.success('保存成功')
							this.open = false
							this.getList()
						})
					}
				})
			},
			handleDetail(row) {
				this.reset()
				this.form = row
				this.studentlist = this.form.attendanceStudents
				this.openDetail = true
			},
			close() {
				this.status = ''
				this.searchStr = ''
				this.openDetail = false
				this.getList()
			},
			handleEdit(row) {
				this.reset()
				this.form = row
				this.studentlist = this.form.attendanceStudents
				this.open = true
			},
			reset() {
				this.form = {
					id: undefined,
					termName: undefined,
					classId: undefined,
					className: undefined,
					shangkeTime: undefined,
					shangkeTeacher: undefined,
					attendanceStudents: [],
				}
				this.resetForm('form')
			},
			getClassStudent(deptId) {
				getClassById({
					deptId
				}).then((res) => {
					if (res.data && res.data.length > 0) {
						res.data.forEach((item) => {
							this.form.attendanceStudents.push({
								userId: item.userId,
								userName: item.userName,
								loginName: item.loginName,
								attendanceStatus: '1',
								unattendanceCause: '',
							})
						})
						this.studentlist = this.form.attendanceStudents
						this.open = true
					}
				})
			},
			// 按年级查学期
			getTermList() {
				// 取年级
				let gr = this.classlist.filter(v => v.deptId == this.queryParams.classId)[0].gradeYear
				getTerm({
					gradeYear: gr
				}).then(res => {
					let d = res.data
					this.termlist = d
					this.queryParams.termName = d[0].termName
					this.queryParams.grade = gr
					this.getList()
				})
			}
		},
	}
</script>
<style lang="scss" scoped>
	@import '@/assets/scss/el-tabs.scss';
	@import '@/assets/scss/studentResult.scss';
</style>
<style scoped>
.el-form-item>>>.el-form-item__label {
	font-weight: bold;
	color: #303133;
}
</style>