<template>
	<div class="web-content">
		<div class="search">
		  <el-form :inline="true" :model="queryParams" ref="queryParams">
				<el-row type="flex" justify="space-between" align="middle">
					<el-col :span="14" style="height: 55px">
						<el-form-item prop="classId">
							<el-select
								v-model="queryParams.classId"
								placeholder="请选择班级"
								style="width: 150px"
								@change="getTermList"
							>
								<el-option
									v-for="(item, index) in classlist"
									:key="index"
									:label="item.deptName"
									:value="item.deptId"
								></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-select
								v-model="queryParams.termName"
								placeholder="请选择学期"
								style="width: 200px"
							>
								<el-option v-for="item, index in termlist" :key="index" :label="item.termName" :value="item.termName"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="10" style="height: 55px; text-align: right">
						<el-form-item prop="userNameOrLoginName">
							<el-input
								v-model="queryParams.userNameOrLoginName"
								placeholder="学生姓名/学籍号"
								clearable
								style="width: 200px"
								@keyup.enter.native="handleQry"
							/>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="handleQry">查询</el-button>
						</el-form-item>
					</el-col>
				</el-row>
		  </el-form>
		</div>
		<el-row type="flex" justify="space-between">
			<el-col :span="6">
				<h3>艺术技能测试</h3>
			</el-col>
			<el-col :span="6" class="d-flex d-flex-end">
				<download-excel :data="tableData" class="download-btn" :fields="exportTh" name="学生体育技能中考成绩.xls">
					<el-button icon="el-icon-download" :disabled="tableData.length == 0" plain size="mini">导出</el-button>
				</download-excel>
			</el-col>
		</el-row>
		<el-table :data="tableData" style="width: 100%" border :header-cell-style="tableHeaderColor"
			:cell-style="cellStyleFun">
			<el-table-column type="index" label="序号" width="50"></el-table-column>
			<el-table-column prop="userName" label="学生姓名"></el-table-column>
			<el-table-column prop="loginName" label="学籍号" width="180"></el-table-column>
			<el-table-column prop="className" label="所在班级" width="100"></el-table-column>
			<el-table-column prop="termName" label="学期" width="180"></el-table-column>
			<el-table-column prop="nameOne" label="项目1"></el-table-column>
			<el-table-column prop="nameOneScore" label="项目1成绩"></el-table-column>
			<el-table-column prop="nameTwo" label="项目2"></el-table-column>
			<el-table-column prop="nameTwoScore" label="项目2成绩"></el-table-column>
			<el-table-column prop="avgScore" label="平均成绩"></el-table-column>
		</el-table>
	</div>
</template>

<script>
import { getTerm } from '@/api/public/search.js'
import { artSkillScoreList } from '@/api/teacher/quality/artistic'
export default {
	data() {
		return {
			queryParams: {
				termName: '',
				grade: '',
				classId: '',
				userNameOrLoginName: '',
				pageNum: 1,
				pageSize: 15
			},
			typeId: '333',
			tableData: [],
			total: 0,
			termlist: [],
			classlist: [],
			exportTh: {
				"学生姓名": "userName",
				"学籍号": "loginName",
				"所在班级": "className",
				"学期": "termName",
				"项目1": "nameOne",
				"项目1成绩": "nameOneScore",
				"项目2": "nameTwo",
				"项目2成绩": "nameTwoScore",
				"平均成绩": "avgScore",
			}
		}
	},
	methods: {
		// 初始化
		getFirst() {
			this.$store.dispatch('GetClassList', this.typeId).then((res) => {
				this.classlist = res.data
				this.queryParams.classId = this.$store.getters.queryForm.deptId
				this.getTermList()
			})
		},
		// 主列表
		getList() {
			this.tableData = []
			artSkillScoreList(this.queryParams).then((res) => {
				let d = res.data
				this.tableData = d.list
				this.total = d.total
			})
		},
		// 重置查询
		handleQry() {
			this.queryParams.pageNum = 1
			this.getList()
		},
		// 按年级查学期
		getTermList() {
			// 取年级
			let gr = this.classlist.filter(v => v.deptId == this.queryParams.classId)[0].gradeYear
			getTerm({
				gradeYear: gr
			}).then(res => {
				let d = res.data
				this.termlist = d
				this.queryParams.termName = d[0].termName
				this.queryParams.grade = gr
				this.getList()
			})
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/el-tabs.scss';
@import '@/assets/scss/studentResult.scss';
</style>