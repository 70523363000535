<template>
  <div class="web-content">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="艺术技能测试" name="1">
        <ArtSkillScore ref="artSkillScore"></ArtSkillScore>
      </el-tab-pane>
      <el-tab-pane label="艺术展演" name="2">
				<ArtActivity ref="artActivity"></ArtActivity>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ArtSkillScore from './components/artSkillScore.vue'
import ArtActivity from './components/artActivity.vue'
export default {
  data() {
    return {
      activeName: '1',
    }
  },
  components: { ArtActivity, ArtSkillScore },
  methods: {
    handleClick() {
      this.$nextTick(() => {
        switch(this.activeName) {
          case '1': this.$refs.artSkillScore.getFirst(); break;
          case '2': this.$refs.artActivity.getFirst(); break;
        }
      })
    }
  }
}
</script>