<template>
  <div class="web-content">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="艺术比赛" name="1">
        <ArtMatch ref="artMatch"></ArtMatch>
      </el-tab-pane>
      <el-tab-pane label="现场测评" name="2">
        <LiveTest ref="liveTest"></LiveTest>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ArtMatch from './components/artMatch.vue'
import LiveTest from './components/liveTest.vue'
export default {
  data() {
    return {
      activeName: '1',
    }
  },
  components: { ArtMatch, LiveTest },
  methods: {
    handleClick() {
      this.$nextTick(() => {
        switch(this.activeName) {
          case '1': this.$refs.artMatch.getFirst(); break;
          case '2': this.$refs.liveTest.getFirst(); break;
        }
      })
    }
  }
}
</script>