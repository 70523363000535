<template>
  <div class="web-content">
    <div class="search">
      <el-form :inline="true" :model="queryParams" ref="queryParams">
    		<el-row type="flex" justify="space-between" align="middle">
    			<el-col :span="14" style="height: 55px">
    				<el-form-item prop="deptId">
    					<el-select
    						v-model="queryParams.deptId"
    						placeholder="请选择班级"
    						style="width: 150px"
    						@change="getTermList"
    					>
    						<el-option
    							v-for="(item, index) in classlist"
    							:key="index"
    							:label="item.deptName"
    							:value="item.deptId"
    						></el-option>
    					</el-select>
    				</el-form-item>
    				<el-form-item>
    					<el-select
    						v-model="queryParams.termName"
    						placeholder="请选择学期"
    						style="width: 200px"
    					>
    						<el-option v-for="item, index in termlist" :key="index" :label="item.termName" :value="item.termName"></el-option>
    					</el-select>
    				</el-form-item>
    			</el-col>
    			<el-col :span="10" style="height: 55px; text-align: right">
    				<el-form-item prop="userNameOrLoginName">
    					<el-input
    						v-model="queryParams.userNameOrLoginName"
    						placeholder="学生姓名/学籍号"
    						clearable
    						style="width: 200px"
    					/>
    				</el-form-item>
    				<el-form-item>
    					<el-button type="primary" @click="handleQry">查询</el-button>
    				</el-form-item>
    			</el-col>
    		</el-row>
      </el-form>
    </div>
    <el-row type="flex" justify="space-between">
      <el-col :span="6">
        <h3>艺术知识测评</h3>
      </el-col>
      <el-col :span="18" class="btn" style="text-align: right">
        <el-button type="primary" plain
          ><download-excel
            :data="tableData"
            :fields="fields"
            name="艺术知识测评.xls"
          >
            导出
          </download-excel></el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      :header-cell-style="tableHeaderColor"
      :cell-style="cellStyleFun"
    >
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column prop="userName" label="学生姓名"> </el-table-column>
      <el-table-column prop="loginName" label="学籍号" width="180">
      </el-table-column>
      <el-table-column prop="className" label="班级" width="120">
      </el-table-column>
      <el-table-column prop="evaResult" label="测评结果" width="120">
      </el-table-column>
      <el-table-column prop="indexScore" label="综素得分" width="120">
      </el-table-column>
      <el-table-column prop="evaStatus" label="测评状态"> </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button @click="handleDetail(scope.row)" type="text" size="small"
            >查看详情</el-button
          >
          <el-button @click="handleUrge(scope.row)" type="text" size="small"
            >催一下</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />
  </div>
</template>

<script>
import { getTerm } from '@/api/public/search.js'
import {
  getArtTestList,
  getArtTestDetail,
  hurryUp,
} from "@/api/teacher/quality/artistic";
export default {
  data() {
    return {
      tableData: [],
      title: "",
      open: false,
      form: {},
      total: 0,
      queryParams: {
				deptId: '',
				termName: '',
				userNameOrLoginName: '',
        typeId: "402",
				pageNum: 1,
        pageSize: 15,
      },
      typeId: "333",
      termlist: [],
      classlist: [],
      fields: {
        学生姓名: "userName",
        学籍号: "loginName",
        班级: "className",
        测评结果: "evaResult",
        综素得分: "indexScore",
        测评状态: "evaStatus",
      },
    };
  },
  methods: {
    getFirst() {
      this.$store.dispatch("GetClassList", this.typeId).then((res) => {
        this.classlist = res.data;
        this.queryParams.deptId = this.$store.getters.queryForm.deptId
        this.getTermList()
      });
    },
    getList(status) {
      getArtTestList(this.queryParams).then((res) => {
        let arr = res.data.list;
        if (status != undefined && status.length > 0) {
          this.tableData = arr.filter((item) => {
            return item.evaStatus == status;
          });
        } else {
          this.tableData = arr;
        }
        this.total = res.data.size;
      });
    },
    handleQry() {
			this.queryParams.pageNum = 1
      this.getList();
    },
    handleDetail(row) {
      if (row.evaStatus == "已测评") {
        const params = {
          term: this.queryParams.term,
          evaResult: row.evaResult,
          id: row.id,
          questionnaireId: row.questionnaireId,
          typeId: row.typeId,
          userId: row.userId,
		  evaTypeId: '402'
        };
        this.$router.push({
          name: "questionnaire",
          params: params,
        });
        sessionStorage["params"] = JSON.stringify(params);
      } else {
        this.$message.warning("该学生还未做测评，你可以催一下");
      }
    },
    handleUrge(row) {
      if (row.evaStatus == "未测评") {
        hurryUp({
          content: "你的健康知识测评还未完成，老师催你了",
          msgType: "402",
          userId: row.userId,
        }).then((res) => {
          this.$message.success("已向该学生发送催促消息！");
        });
      } else {
        this.$message.warning("该学生已做完测评，不用再催啦！");
      }
    },
		// 按年级查学期
		getTermList() {
			// 取年级
			let gr = this.classlist.filter(v => v.deptId == this.queryParams.deptId)[0].gradeYear
			getTerm({
				gradeYear: gr
			}).then(res => {
				let d = res.data
				this.termlist = d
				this.queryParams.termName = d[0].termName
				this.queryParams.grade = gr
				this.getList()
			})
		}
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/el-tabs.scss";
@import "@/assets/scss/studentResult.scss";
.web-contain {
  width: 100%;
}
</style>
<style scoped>
.el-form-item {
  margin-bottom: 12px;
}
.el-form-item {
  margin-bottom: 12px;
}
.el-form-item >>> .el-form-item__label {
  font-weight: bold;
  color: #303133;
}
.el-image {
  width: 300px;
}
</style>