<template>
	<div class="web-content">
		<div class="search">
		  <el-form :inline="true" :model="queryParams" class="demo-form-inline">
				<el-row type="flex" justify="space-between" align="middle">
					<el-col :span="14" style="height: 55px">
						<el-form-item prop="classId">
						  <el-select
						    v-model="queryParams.classId"
						    placeholder="班级"
						    style="width: 120px"
								@change="getTermList"
						  >
						    <el-option v-for="item,index in classlist" :key="index" :label="item.deptName" :value="item.deptId"></el-option>
						  </el-select>
						</el-form-item>
						<el-form-item prop="termName">
						  <el-select
						    v-model="queryParams.termName"
						    placeholder="学期"
						    style="width: 188px"
						  >
						    <el-option v-for="item,index in termlist" :key="index" :label="item.termName" :value="item.termName"></el-option>
						  </el-select>
						</el-form-item>
					</el-col>
					<el-col :span="10" style="height: 55px;text-align: right">
					  <el-form-item>
					    <el-input
					      v-model="queryParams.userNameOrLoginName"
					      placeholder="输入学生姓名/学籍号"
					      style="width: 283px"
					      clearable
					    ></el-input>
					  </el-form-item>
					  <el-form-item>
					    <el-button type="primary" @click="getList">搜索</el-button>
					  </el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<el-row type="flex" justify="space-between">
			<el-col :span="6">
				<h3>艺术课程学习任务完成度</h3>
			</el-col>
			<el-col :span="6" class="d-flex d-flex-end">
				<el-button type="primary" size="small" plain style="margin-right: 10px;" @click="openImp">导入</el-button>
				<download-excel :data="tableData" :fields="exportTh" name="艺术课堂参与度.xls">
					<el-button plain :disabled="tableData.length == 0" size="small">导出</el-button>
				</download-excel>
			</el-col>
		</el-row>
		<el-table :data="tableData" style="width: 100%" border :header-cell-style="tableHeaderColor"
			:cell-style="cellStyleFun">
			<el-table-column type="index" fixed="left" label="序号" width="50"> </el-table-column>
			<el-table-column prop="userName" fixed="left" label="学生姓名" width="150"> </el-table-column>
			<el-table-column prop="loginName" label="学籍号" width="200"> </el-table-column>
			<el-table-column prop="className" label="班级" width="150"></el-table-column>
			<el-table-column prop="courseOne" label="课程1名称" width="120"></el-table-column>
			<el-table-column prop="learnProgressOne" label="课程1学习任务完成度" width="180"></el-table-column>
			<el-table-column prop="courseTwo" label="课程2名称" width="120"></el-table-column>
			<el-table-column prop="learnProgressTwo" label="课程2学习任务完成度" width="180"></el-table-column>
			<el-table-column prop="avgLearnProgress" fixed="right" label="平均完成度" width="120"></el-table-column>
		</el-table>
		<el-dialog title="导入数据" :visible.sync="importModal" width="600px" center>
			<el-form :model="importForm" :rules="rules" ref="importForm" label-width="150px">
			  <el-form-item label="班级：" prop="classId">
					<el-select
						v-model="importForm.classId"
						placeholder="请选择班级"
						style="width: 240px"
					>
						<el-option
							v-for="(item, index) in classlist"
							:key="index"
							:label="item.deptName"
							:value="item.deptId"
						></el-option>
					</el-select>
				</el-form-item>
			</el-form>
		  <el-row :gutter="20" type="flex" justify="center">
		    <el-col :span="1.5">
		      <el-button type="primary" size="small" @click="downloadTpl">下载模板</el-button>
		    </el-col>
		    <el-col :span="1.5">
		      <el-upload
		        action=""
		        :on-change="handleUpChange"
		        :on-remove="handleRemove"
		        :on-exceed="handleExceed"
		        :file-list="upload.fileList"
		        :limit="upload.limit"
		        :auto-upload="false"
		        :show-file-list="true"
		      >
		        <el-button size="small" type="primary">选择文件</el-button>
		      </el-upload>
		    </el-col>       
		  </el-row>
		  <p style="font-size:12px;color: red;text-align:center">注：请先下载导入模板，并在原文件内填写，再导入该文件</p>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="cancel">取 消</el-button>
		    <el-button type="primary" :disabled="upload.fileList.length == 0" @click="submitImport">确 定</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
import { artLearningProgressList, artLearningProgressImp, artLearningProgressTpl } from '@/api/teacher/quality/artistic'
import { getTerm } from '@/api/public/search.js'
export default {
	data() {
		return {
			queryParams: {
				termName: '',
				classId: '',
				userNameOrLoginName: '',
				type: 2
			},
			typeId: '333',
			tableData: [],
			termlist: [],
			classlist: [],
			importModal: false,
			importForm: {
				classId: '',
				type: 2,
				file: {}
			},
			upload: {
				limit: 1,
				fileList: []
			},
			rules: {
				classId: [{
					required: true,
					message: '请选择班级',
					trigger: 'blur'
				}]
			},
			exportTh: {
				"学生姓名": "userName",
				"学籍号": "loginName",
				"班级": "className",
				"课程1名称": "courseOne",
				"课程1学习任务完成度": "learnProgressOne",
				"课程2名称": "courseTwo",
				"课程2学习任务完成度": "learnProgressTwo",
				"平均完成度": "avgLearnProgress",
			}
		}
	},
	methods: {
		// 初始化
		getFirst() {
			this.$store.dispatch('GetClassList', this.typeId).then((res) => {
				this.classlist = res.data
				this.queryParams.classId = this.$store.getters.queryForm.deptId
				this.getTermList()
			})
		},
		// 主列表
		getList() {
			this.tableData = []
			artLearningProgressList(this.queryParams).then((res) => {
				this.tableData = res.data
			})
		},
		// 打开导入弹窗
		openImp() {
			this.importForm.classId = ''
			this.upload.fileList = []
			this.importModal = true
		},
		// 文件上传
		handleUpChange(file) {
			this.upload.fileList = [file.raw]
			this.$message.success('导入成功')
		},
		// 删上传
		handleRemove() {
			this.upload.fileList = []
		},
		// 个数超限
		handleExceed() {
			this.$message.success(`每次只允许上传${ this.upload.limit }个文件`)
		},
		// 下载模板
		downloadTpl() {
			if (!this.importForm.classId) {
				this.$message.warning('请选择班级')
			} else {
				let fd = new FormData()
				fd.append('type', this.importForm.type)
				fd.append('classId', this.importForm.classId)
				artLearningProgressTpl(fd).then(res => {
					let url = window.URL.createObjectURL(new Blob([res], {type: 'application/x-xls'}))
					let link = document.createElement('a')
					link.style.display = 'none'
					link.href = url
					link.setAttribute('download', "艺术课堂参与度导入模板.xls")
					document.body.appendChild(link)
					link.click()
					window.URL.revokeObjectURL(url);//释放blob
				})
			}
		},
		// 确认导入
		submitImport() {
			let fd = new FormData()
			fd.append('type', this.importForm.type)
			fd.append('classId', this.importForm.classId)
			fd.append('file', this.upload.fileList[0])
			artLearningProgressImp(fd).then(res => {
				this.$message.success('导入成功')
				this.importModal = false
				this.importForm.classId = ''
				this.upload.fileList = []
				this.getList()
			})
		},
		// 取消上传
		cancel() {
			this.importModal = false
		},
		// 按年级查学期
		getTermList() {
			// 取年级
			let gr = this.classlist.filter(v => v.deptId == this.queryParams.classId)[0].gradeYear
			getTerm({
				gradeYear: gr
			}).then(res => {
				let d = res.data
				this.termlist = d
				this.queryParams.termName = d[0].termName
				this.queryParams.grade = gr
				this.getList()
			})
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/el-tabs.scss';
@import '@/assets/scss/studentResult.scss';
</style>