<template>
  <div class="web-content">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="艺术知识测评" name="1">
        <ArtTest ref="artTest"></ArtTest>
      </el-tab-pane>
      <el-tab-pane label="艺术课出勤率" name="2">
        <ArtAttendRate ref="artAttendRate"></ArtAttendRate>
      </el-tab-pane>
      <el-tab-pane label="艺术课考勤" name="3">
        <ArtAttend ref="artAttend"></ArtAttend>
      </el-tab-pane>
			<el-tab-pane label="艺术课堂学习参与度" name="4">
			  <ArtClsParticipate ref="artClsParticipate"></ArtClsParticipate>
			</el-tab-pane>
			<el-tab-pane label="艺术课程学习任务完成度" name="5">
			  <ArtClsTaskCompleteRt ref="artClsTaskCompleteRt"></ArtClsTaskCompleteRt>
			</el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ArtTest from './components/artTest.vue'
import ArtAttendRate from './components/artAttendRate.vue'
import ArtAttend from './components/artAttend.vue'
import ArtClsParticipate from './components/artClsParticipate.vue'
import ArtClsTaskCompleteRt from './components/artClsTaskCompleteRt.vue'
export default {
  data() {
    return {
      activeName: '1',
    };
  },
  components: { ArtTest, ArtAttendRate, ArtAttend, ArtClsParticipate, ArtClsTaskCompleteRt },
  methods: {
    handleClick() {
      this.$nextTick(() => {
        switch(this.activeName) {
          case '1': this.$refs.artTest.getFirst(); break;
          case '2': this.$refs.artAttendRate.getFirst(); break;
          case '3': this.$refs.artAttend.getFirst(); break;
					case '4': this.$refs.artClsParticipate.getFirst(); break;
					case '5': this.$refs.artClsTaskCompleteRt.getFirst(); break;
        }
      })
    }
  }
}
</script>